const textElement = document.getElementById('logo')
const originalText = textElement.innerText
const replacementChars = ['✿', '↑', '✺', '⁂', '❋', '⊙', '•', '❥']
const maxReplacements = Math.floor(originalText.length / 3)
const recentReplacements = new Set()
let activeReplacements = []
let isHovered = false

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function getUniqueReplacementChar() {
  // Get a unique replacement character not in recentReplacements
  let availableChars = replacementChars.filter(
    (c) => !recentReplacements.has(c)
  )
  if (availableChars.length === 0) {
    availableChars = replacementChars.slice() // Reset if all chars are used
    recentReplacements.clear()
  }
  const randomChar = availableChars[getRandomInt(availableChars.length)]
  recentReplacements.add(randomChar)
  return randomChar
}

function replaceRandomCharacter() {
  // If we've reached the maximum number of replacements, do nothing
  if (activeReplacements.length >= maxReplacements || isHovered) return

  let randomIndex

  // Ensure we don't replace spaces and avoid replacing the same index currently replaced
  do {
    randomIndex = getRandomInt(originalText.length)
  } while (
    originalText[randomIndex] === ' ' ||
    activeReplacements.includes(randomIndex)
  )

  const randomChar = getUniqueReplacementChar()

  // Replace character
  const newTextArray = textElement.innerText.split('')
  newTextArray[randomIndex] = randomChar
  textElement.innerText = newTextArray.join('')

  // Track the replacement
  activeReplacements.push(randomIndex)

  // Restore original text after 1 second
  setTimeout(() => {
    activeReplacements = activeReplacements.filter(
      (index) => index !== randomIndex
    )
    const restoredTextArray = textElement.innerText.split('')
    restoredTextArray[randomIndex] = originalText[randomIndex]
    textElement.innerText = restoredTextArray.join('')
  }, 1000)
}

function handleMouseEnter() {
  isHovered = true
  textElement.innerText = originalText // Restore original text immediately on hover
}

function handleMouseLeave() {
  isHovered = false
}

textElement.addEventListener('mouseenter', handleMouseEnter)
textElement.addEventListener('mouseleave', handleMouseLeave)

setInterval(replaceRandomCharacter, 200)
